import React, { useEffect, useRef, useState } from "react";
import FileUpload from "./FileUpload";
import TextField from "./form/TextField";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormDataLeadBusiness } from "../types";
import useBusiness from "../hooks/useBusiness";
import ReCAPTCHA from "react-google-recaptcha";
import { useSellBusiness } from "../contexts/sellBusinessContext";

const SellBusinessModal = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isOpen, handleClose } = useSellBusiness();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<FormDataLeadBusiness>();

  const { onSave } = useBusiness();

  const onSubmit: SubmitHandler<FormDataLeadBusiness> = async (data) => {
    const token = await recaptchaRef?.current?.executeAsync();
    setIsLoading(true);

    try {
      await onSave(data, token || "");
      setIsSuccess(true);
      reset();
    } catch (e: any) {
      console.warn(e);
      setError("Error sending data, please try again later");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        setError(null);
        setIsSuccess(false);
        handleClose();
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLoading]);

  return (
    <div
      className={`fixed w-full h-full left-0 top-0 z-50 transition-opacity duration-700 ${
        isOpen ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        onClick={handleClose}
        className="absolute w-full h-full left-0 top-0 bg-black opacity-35"
      ></div>
      <ReCAPTCHA
        className="opacity-0"
        ref={recaptchaRef}
        sitekey={
          process.env.GATSBY_RECAPTCHA_SITE_KEY ||
          "6LfLesUpAAAAANlz01VfQfUMJIm5Ssn0AnnomGbB"
        }
        size="invisible"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="z-10 rounded-lg absolute sm:top-2/4 top-3 sm:left-2/4 left-3 sm:right-auto right-3 sm:-translate-x-2/4 sm:-translate-y-2/4 xl:w-2/6 lg:w-2/4 sm:w-3/4 bg-white sm:p-6 p-3 flex flex-col align-top sm:gap-4"
      >
        <div
          onClick={handleClose}
          className="absolute cursor-pointer top-2 right-2 text-sm font-bold border border-black w-7 h-7 flex items-center justify-center rounded-full font-sans"
        >
          <span className="-mt-[1px]">x</span>
        </div>
        <div className="mb-3">
          <h3 className="sm:text-lg text-md font-semibold sm:text-center">
            Tell us more about your business
          </h3>
        </div>
        <div className="flex flex-col flex-1 sm:gap-6 gap-4 overflow-auto no-scrollbar">
          <div className="flex gap-4">
            <TextField
              disabled={isLoading}
              required="Please fill your first name"
              register={register}
              type="text"
              id="first_name"
              label="First name"
              placeholder="Your first name"
              error={errors.first_name?.message}
            />
            <TextField
              disabled={isLoading}
              required="Please fill your last name"
              register={register}
              type="text"
              id="last_name"
              label="Last name"
              placeholder="Your last name"
              error={errors.last_name?.message}
            />
          </div>
          <TextField
            disabled={isLoading}
            required="Please fill your email"
            register={register}
            type="email"
            id="email"
            label="Email"
            placeholder="Your email"
            error={errors.email?.message}
          />
          <TextField
            disabled={isLoading}
            required="Please fill your company name"
            register={register}
            type="text"
            id="company_legal_name"
            label="Company (Legal name)"
            placeholder="Your company name"
            error={errors.company_legal_name?.message}
          />
          <TextField
            disabled={isLoading}
            required="Please fill summary"
            register={register}
            field="textarea"
            type="text"
            id="summary"
            label="Summary"
            placeholder="Summary about your business and operations"
            error={errors.summary?.message}
          />
          <TextField
            disabled={isLoading}
            required={false}
            register={register}
            type="url"
            id="website"
            label="Website"
            placeholder="https://website.com"
          />
          <FileUpload
            disabled={isLoading}
            id="files"
            label="Files"
            register={register}
            required="Please upload some files"
            error={errors.files?.message}
            onChange={setValue}
            isClear={isSuccess}
            clearErrors={clearErrors}
          />
        </div>
        <div className="text-center">
          <button
            className="block w-full sm:py-3 py-2 bg-blue-400 rounded-md font-semibold sm:text-md text-xs text-white"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
        {isSuccess && (
          <div className="rounded-md bg-green-200 p-3">
            <p className="text-xs font-semibold text-center">
              Thanks for sharing your company information with us. That could be
              the beginning of a great partnership.
              <br />
              We will review and get back to you in the next few days.
            </p>
          </div>
        )}

        {!!error && !isSuccess && (
          <div className="rounded-md bg-red-200 p-3">
            <p className="text-xs font-semibold text-center">{error}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default SellBusinessModal;
