import React from "react";
import SellBusinessModal from "./SellBusinessModal";
import { SellBusinessProvider } from "../contexts/sellBusinessContext";

const Layout = ({ children }) => (
  <div className="layout">
    <SellBusinessProvider>
      {children}
      <SellBusinessModal />
    </SellBusinessProvider>
  </div>
);

export default Layout;
