import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "../images/icons/file-upload.svg";
import DeleteIcon from "../images/icons/delete.svg";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
} from "react-hook-form";
import { FormDataLeadBusiness } from "../types";

interface FileInputProps {
  id: Path<FormDataLeadBusiness>;
  label: string;
  register: UseFormRegister<FormDataLeadBusiness>;
  required?: string | ValidationRule<boolean>;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  onChange: UseFormSetValue<FormDataLeadBusiness>;
  clearErrors: UseFormClearErrors<FormDataLeadBusiness>;
  isClear: boolean;
  disabled?: boolean;
}

function FileInput({
  id,
  label,
  register,
  required,
  error,
  onChange,
  clearErrors,
  isClear,
  disabled,
}: FileInputProps) {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    setFiles([]);
  }, [isClear]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => {
      const files = [...prevFiles, ...acceptedFiles];

      onChange(id, files);
      clearErrors(id);
      return files;
    });
  }, []);

  const removeFile = (fileToRemove) => {
    setFiles((prevFiles) => {
      const files = prevFiles.filter((file) => file !== fileToRemove);

      onChange(
        id,
        prevFiles.filter((file) => file !== fileToRemove)
      );

      clearErrors(id);

      return files;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    accept: {
      "image/png": [".png"],
      "video/mp4": [".mp4"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
    },
    maxFiles: 5,
  });

  return (
    <div>
      <span className="sm:text-sm text-xs block mb-2 font-semibold">
        {label}
        {required ? <span className="ml-1 text-red-500">*</span> : ""}
      </span>
      <div
        {...getRootProps()}
        className={`py-5 border-dashed border rounded-md text-center
          ${!!error ? "border-red-500" : "border-gray-500"}
          ${disabled ? "curso-none bg-gray-200" : "cursor-pointer"}
        `}
      >
        <img className="sm:w-8 w-5 mx-auto sm:mb-3 mb-1" src={FileUploadIcon} />
        <input
          type="file"
          disabled={disabled}
          {...register(id, { required })}
          {...getInputProps()}
        />
        <p className="text-xs">
          {isDragActive
            ? "Drop the files here..."
            : "Drag and drop files here, or click to select files"}
        </p>
      </div>
      {error && (
        <p className="to-100% sm:text-md text-xs sm:mt-0 -mt-[1px] text-red-500">
          <small>{error.toString()}</small>
        </p>
      )}
      <p className="text-right mr-2 text-xs">
        <small>
          Accepted types: .png, .jpg, .jpeg, .mp4, .mov, .pdf, .xls, .xlsx, .csv
        </small>
      </p>
      <ul className="flex flex-col gap-2 mt-4">
        {files.map((file, index) => (
          <li
            className="flex justify-between bg-slate-200 py-3 px-4 rounded-md"
            key={index}
          >
            <span className="font-semibold text-xs block">{file.name} </span>
            <button onClick={() => removeFile(file)}>
              <img className="w-4" src={DeleteIcon} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FileInput;
