import { graphql, useStaticQuery } from "gatsby";
import saveLeadBusiness from "../services/lead_business";
import { FormDataLeadBusiness } from "../types";
import axios from "axios";

const useBusiness = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          apiUrl
        }
      }
    }
  `);

  const { apiUrl } = data.site.siteMetadata;

  const endpointUrl = `${apiUrl}v1/leads/business`;

  const handleSave = async (
    payload: FormDataLeadBusiness,
    token: string | null
  ) => {
    const formData = new FormData();

    formData.append("first_name", payload.first_name);
    formData.append("last_name", payload.last_name);
    formData.append("email", payload.email);
    formData.append("company", payload.company_legal_name);
    formData.append("summary", payload.summary);
    formData.append("website", payload.website);
    formData.append("channel", "website");

    payload.files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    try {
      const response = await axios.post(`${endpointUrl}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "X-Recaptcha-Token": token,
          "Skrol-Channel": "website",
        },
      });
      if (response) {
        return response.data;
      } else {
        throw new Error("Error during login");
      }
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  };

  return {
    onSave: handleSave,
  };
};

export default useBusiness;
